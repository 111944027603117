.App {
    text-align: center;
    background-color: #f8f8f8;
    height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.game_view {
    display: grid;
    grid-template-rows: 2fr 5fr 2fr;
    height: 85vh;
}

.top_panels {
    display: flex;
    padding: 1em;
    justify-content: space-between;
}

.card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto  );
}

.card_name_vignette {
    position: absolute;
    top: 0;
    background-color: #30b362;
    color: white;
    border-radius: 2px;
    font-size: 1em;
    padding: 0.2em;
}

.card_mode_selection {
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: solid 1px lightgray;
    display: flex;
    justify-content: space-evenly;
}

.card_mode_selection > button {
    font-size: 1.5em;

}

.card_pannel {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 0.3em;
    padding-bottom: 0.1em;
    border-radius: 10px;
}

.card_image {
    max-width: 40vw;
}

.foccused_card > * > * > .card_image {
    max-width: 90vw;
}

.foccused_card > button {
    width: 80vw;
    font-size: 2em;
    padding: 0.4em;
}

.proposition_panel {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
}

.possible_panel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.possible_panel > button {
    font-size: 1.1em;
}

button {
    background: #ececec;
    border-radius: 5px;
    border: none;
}

button.highlight {
    background-color: #30b362;
    color: white;
}

.try_button {
    padding-left: 0.4em;
    padding-right: 0.4em;
    margin: 0.2em;
    font-size: 1.2em;
}

.select_value_panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 1em;
    padding-right: 1em;
    font-size: 1.2em;
}

.select_value_validation > button {
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    margin: 0.1em;
    margin-right: 1em;
    margin-left: 1em;
    font-size: large;
}

.question_title {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1em;
    text-align: center;
    align-items: center;
}

.question_title > button {
    font-size: 1.2em;
    padding: 0.3em
}

.question_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.question_button {
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    margin: 0.1em;
    margin-right: 1em;
    margin-left: 1em;
    font-size: large;
}

.question_button.false {
    background-color: #ff0000;
    color: white;
}

.question_button.true {
    background-color: #30b362;
    color: white;
}

.solved_view {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100vw;
    padding-left: 10vw;
    padding-right: 10vw;
}

.solved_view > div {
    top: 30%;
    position: relative;
    background-color: white;
    border-radius: 32px;
    width: 80vw;
    padding-top: 0.5em;
    padding-bottom: 2em;
}

.solved_view .success {
    color: darkgreen;
}

.solved_view .failed {
    color: darkred
}

.restart_button {
    background-color: transparent;
    color: #30b362;
}

.header {
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.header > h1 {
    margin: 0;
}

.notes {
    position: absolute;
    top: 0;
}

.game_configuration {
    margin: 10vw;
    margin-top: 40vh;
    background-color: white;
    border-radius: 32px;
    padding: 0.3em;
    padding-bottom: 2em;
}
.game_configuration>* >button{
    margin:0.2em;
    font-size: 2em;
    font-weight: bolder;
}
.game_configuration >button{
    margin-top:1em;
    font-size: 3em;
    font-weight: bolder;
}